import { graphql } from "gatsby"
import React from "react"
import SEORevamp from "../components/common/SEO_Revamp"
import ModelMeeting from "../components/common/meeting-model/Booking"
import EventDetaisl from "../components/event-details-page/EventDetails"
import MainLayout from "../layouts/MainLayout"

const EventDetails = ({ data }) => {
  const upComingPost = data?.allStrapiEventUpcoming?.nodes
  const CTA = data?.allStrapiEventUpcoming?.nodes[0].cta
  const [show, setShow] = React.useState(false)

  return (
    <MainLayout bgChanged={false}>
      <ModelMeeting
        show={show}
        setShow={setShow}
        eventStartDate={upComingPost[0]?.eventStartTime}
        eventEndData={upComingPost[0]?.eventEndTime}
        eventName={CTA?.subTitle}
      />
      <EventDetaisl
        strapiData={data?.strapiEventpost}
        upcomingCTA={data?.allStrapiEventUpcoming?.nodes[0]?.cta}
        setOpen={setShow}
      />
    </MainLayout>
  )
}

export const query = graphql`
  query eventPost($slug: String) {
    strapiEventpost(slug: { eq: $slug }) {
      title
      slug
      rawDesc
      eventImages {
        alternativeText
        caption
        localFile {
          publicURL
        }
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    allStrapiEventUpcoming {
      nodes {
        eventStartTime(formatString: "DD MMMM, YYYY, hh:mm:ss a")
        eventEndTime(formatString: "DD MMMM, YYYY, hh:mm:ss a")
        cta {
          title
          subTitle
          featuredImage {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
      }
    }
  }
`

export default EventDetails

export const Head = ({ data }) => {
  return (
    <SEORevamp
      title={data?.strapiEventpost?.seo?.metaTitle}
      description={data?.strapiEventpost?.seo?.metaDescription}
    />
  )
}
